import useStore from '../Store/market_greeks_data_store';

export const customIndicatorsGetter = function (PineJS) {
  return Promise.resolve([
    {
      name: 'SPX Market GEX',
      metainfo: {
        _metainfoVersion: 53,
        id: "SPX Market GEX@tv-basicstudies-1",
        description: "SPX Market GEX",
        shortDescription: "SPX Market Gex",
        format: { type: "inherit" },
        linkedToSeries: false,
        is_price_study: false,
        plots: [
          { id: "z5ma_plot", type: "line" },
          { id: "plot_baseline", type: "line" }
        ],
        defaults: {
          styles: {
            z5ma_plot: {
              linestyle: 0,
              linewidth: 1,
              plottype: 8, // Default to area plot
              trackPrice: false,
              transparency: 60, // 20% opacity
              visible: true,
              color: "#20a6ee", // Green for positive values

            },
            plot_baseline: {
              linestyle: 0,
              linewidth: 1, // Ensure baseline is visible
              transparency: 0,
              color: "#ffffff", // White baseline
            },
          },
        },
        styles: {
          z5ma_plot: { title: "z_5ma", histogramBase: 0, joinPoints: true },
          plot_baseline: { title: "Baseline" },
        },
        inputs: [], // No user input needed for a fixed baseline
      },
      constructor: function () {
        this.init = function (ctx) {
          this._context = ctx;
          const spxGexData = useStore.getState().spxGexData || [];

          if (!spxGexData || spxGexData.length === 0) {
            this._z5maData = [];
            return;
          }

          const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

          this._z5maData = spxGexData
            .map(entry => {
              const value = entry.z_5ma === null ? 0 : parseFloat(entry.z_5ma);

              // Shift the timestamp forward by one day to force correct alignment
              return {
                time: Math.floor((entry.time + ONE_DAY_IN_MS) / 1000),
                value: value,
              };
            });

          // console.log('Shifted z_5ma data:', this._z5maData);
        };

        this.main = function (ctx) {
          const currentTime = Math.floor(ctx.symbol.time / 1000); // Convert to seconds
          // console.log('Running main method of the Custom z_5ma Indicator...');
          // console.log('Current time from context:', currentTime);

          if (!this._z5maData || this._z5maData.length === 0) {
            // console.warn('No z_5ma data available.');
            return [NaN, 0]; // Return baseline at 0 even if data is missing
          }

          // Find the closest matching `time` in `z5maData`
          let closestMatch = null;
          let smallestDiff = Infinity;
          for (const data of this._z5maData) {
            const timeDiff = Math.abs(data.time - currentTime);
            if (timeDiff < smallestDiff) {
              closestMatch = data;
              smallestDiff = timeDiff;
            }
          }

          if (closestMatch) {
            // console.log(
            //   `Found closest z_5ma value: ${closestMatch.value} for time: ${closestMatch.time}`
            // );

            // Return the value for the plot and 0 for the baseline
            return [closestMatch.value, 0];
          }

          // console.warn(`No close match found for the current time (${currentTime}). Returning NaN.`);
          return [NaN, 0]; // Return baseline at 0 even if no match
        };
      },
    },
  ]);
};




















